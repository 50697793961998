import {
	Form,
	Link,
	useParams,
	useSearchParams,
	useLocation,
} from "@remix-run/react";
import {
	Navbar,
	NavbarBrand,
	NavbarContent,
	NavbarItem,
	NavbarMenuToggle,
	NavbarMenu,
	NavbarMenuItem,
	Button,
	Dropdown,
	DropdownTrigger,
	Avatar,
	DropdownMenu,
	DropdownItem,
	DropdownSection,
} from "@nextui-org/react";
import { useState } from "react";
import { logoify } from "~/utils/string";
import { UserSession } from "~/utils/user-session";
import { SerializeFrom } from "@remix-run/node";
import { ActivitiesPopover } from "./ActivitiesPopover";
import { Plus } from "lucide-react";
import * as m from "$paraglide/messages";
import logo from "~/images/logo-archispec.png";
import { tv } from "tailwind-variants";

export default function NavBar({
	user,
	projects,
	sticky = false,
}: {
	user?: SerializeFrom<UserSession>;
	projects?: { id: number; title: string; role: string }[];
	sticky?: boolean;
}) {
	const [searchParams] = useSearchParams();
	const redirectTo = searchParams.get("redirectTo");
	const { lang } = useParams();
	const onlyClient = projects?.some((project) => project.role === "client");
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const location = useLocation();

	const sections: {
		name: string;
		divider?: boolean;
		items: {
			name: string;
			href: string;
			method?: string;
			color?:
				| "danger"
				| "default"
				| "primary"
				| "secondary"
				| "success"
				| "warning";
		}[];
	}[] = [];

	if (user?.currentCompany?.name) {
		sections.push({
			name: user.currentCompany.name,
			divider: true,
			items: [
				{
					name: m.members(),
					href: `/${lang}/app/companies/${user.currentCompany.id}/settings/members`,
				},
			],
		});
	}

	if (user) {
		sections.push({
			name: `${user.firstName} ${user.lastName}`,
			items: [
				{
					name: m.logout(),
					href: "/logout",
					method: "post",
					color: "danger",
				},
			],
		});
	}

	const handleClickProject = () => {
		setIsMenuOpen(false);
	};

	const isLibraryActive = location.pathname.includes("/app/library/products");
	const isProjectsActive = location.pathname.includes("/app/projects/");

	const navButton = tv({
		base: "",
		variants: {
			active: {
				true: "border",
			},
		},
	});

	return (
		<Navbar
			maxWidth="full"
			isBordered
			isMenuOpen={isMenuOpen}
			onMenuOpenChange={setIsMenuOpen}
			position={sticky ? "sticky" : "static"}
		>
			<NavbarContent className="sm:hidden" justify="start">
				<NavbarMenuToggle
					aria-label={isMenuOpen ? "Close menu" : "Open menu"}
				/>
			</NavbarContent>

			<NavbarContent className="sm:hidden pr-3" justify="center">
				<NavbarBrand>
					<Link to={`/${lang}`}>
						<div className="flex gap-2">
							<img src={logo} width="24" alt="logo archispec" />
						</div>
					</Link>
				</NavbarBrand>
			</NavbarContent>

			<NavbarContent className="hidden sm:flex gap-4" justify="center">
				<NavbarBrand>
					<Link to={`/${lang}`}>
						<div className="flex gap-2">
							<img src={logo} width="24" alt="logo archispec" />
							<div className="font-thin">ARCHISPEC</div>
						</div>
					</Link>
				</NavbarBrand>
			</NavbarContent>

			{user && projects && (
				<NavbarContent className="hidden sm:flex">
					<Dropdown>
						<NavbarItem isActive={isProjectsActive}>
							<DropdownTrigger>
								<Button
									className={navButton({ active: isProjectsActive })}
									radius="sm"
									variant={isProjectsActive ? "flat" : "light"}
								>
									{m.projects()}
								</Button>
							</DropdownTrigger>
						</NavbarItem>

						<DropdownMenu aria-label="Projects">
							<DropdownSection showDivider={!onlyClient}>
								{projects.map((project) => (
									<DropdownItem
										key={project.id}
										href={`/${lang}/${
											project.role === "client" ? "client" : "app"
										}/projects/${project.id}/products`}
										as={Link}
									>
										{project.title}
									</DropdownItem>
								))}
							</DropdownSection>
							{!onlyClient && (
								<DropdownSection aria-label="Preferences">
									<DropdownItem
										key="new"
										className="text-md"
										href={`/${lang}/app/projects/new`}
										onClick={handleClickProject}
										as={Link}
									>
										<div className="flex items-center">
											<Plus size={16} className="mr-2" />
											<div>{m.newProject()}</div>
										</div>
									</DropdownItem>
								</DropdownSection>
							)}
						</DropdownMenu>
					</Dropdown>
					<NavbarItem isActive={isLibraryActive}>
						<Button
							as={Link}
							radius="sm"
							variant={isLibraryActive ? "flat" : "light"}
							className={navButton({ active: isLibraryActive })}
							to={`/${lang}/app/library/products`}
						>
							{m.proud_top_scallop_find()}
						</Button>
					</NavbarItem>
				</NavbarContent>
			)}
			<NavbarContent as="div" justify="end">
				{!user && (
					<>
						<NavbarItem>
							<Button
								as={Link}
								radius="full"
								to={
									redirectTo
										? `/${lang}/signin?redirectTo=${redirectTo}`
										: `/${lang}/signin`
								}
								variant="bordered"
								color="primary"
							>
								{m.signIn()}
							</Button>
						</NavbarItem>
					</>
				)}
				{user && (
					<>
						<ActivitiesPopover user={user} />
						<Dropdown placement="bottom-end">
							<DropdownTrigger>
								<Avatar
									isBordered
									as="button"
									color="secondary"
									name={logoify(`${user.firstName} ${user.lastName}`)}
									size="sm"
									src={undefined}
								/>
							</DropdownTrigger>
							<DropdownMenu
								aria-label="Profile Actions"
								variant="flat"
								items={sections}
							>
								{(section) => (
									<DropdownSection
										title={section.name}
										showDivider={section.divider}
										items={section.items}
										key={section.name}
										className="mb-0"
									>
										{
											// @ts-ignore
											(item) => (
												<DropdownItem
													key={item.name}
													color={item.color}
													textValue={item.name}
													href={item.method !== "post" ? item.href : undefined}
												>
													{item.method === "post" ? (
														<Form action={item.href} method="post">
															<button
																type="submit"
																className="w-full text-left"
															>
																{item.name}
															</button>
														</Form>
													) : (
														item.name
													)}
												</DropdownItem>
											)
										}
									</DropdownSection>
								)}
							</DropdownMenu>
						</Dropdown>
					</>
				)}
			</NavbarContent>

			{user && projects && (
				<NavbarMenu>
					<NavbarMenuItem className="text-md border-b pb-2">
						<Link to={`/${lang}/app/library/products`}>
							{m.proud_top_scallop_find()}
						</Link>
					</NavbarMenuItem>
					<NavbarMenuItem className="text-md border-b pb-2">
						{m.projects()}
					</NavbarMenuItem>
					{projects.map((project) => (
						<NavbarMenuItem key={project.id} className="text-md">
							<Link
								to={`${project.role === "client" ? "client" : "app"}/projects/${
									project.id
								}/products`}
								onClick={handleClickProject}
							>
								{project.title}
							</Link>
						</NavbarMenuItem>
					))}
					{!onlyClient && (
						<NavbarMenuItem key="new" className="text-md">
							<Link
								to={`/${lang}/app/projects/new`}
								onClick={handleClickProject}
							>
								<div className="flex items-center">
									<Plus size={16} className="mr-2" />
									<div>{m.newProject()}</div>
								</div>
							</Link>
						</NavbarMenuItem>
					)}
				</NavbarMenu>
			)}
		</Navbar>
	);
}
